import {
  EyeIcon,
  GiftIcon,
  HandThumbUpIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { Comment } from "../api";
import {
  CollaborationThread,
  Group,
  GroupAutocompleteResult,
} from "../api/types";
import { useCurrentUser } from "./AuthProvider";
import Avatar from "./Avatar";
import CommentInput from "./CommentInput";
import { ConfirmationModal } from "./ConfirmationModal";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import SpinnerCustom from "./SpinnerCustom";
import Tooltip from "./Tooltip";

interface CommentListProps {
  isLoading: boolean;
  comments: Comment[];
  deleteComment: (id: number) => void;
  editComment: (comment: Comment) => void;
  canLoadMore: boolean;
  loadMore: () => void;
  updateVote?: (commentId: number) => void;
  updateReward?: (commentId: number) => void;
  thread?: CollaborationThread | null;
  showVisibility?: boolean;
}

export default function CommentList({
  isLoading,
  comments,
  canLoadMore,
  loadMore,
  deleteComment,
  editComment,
  updateVote,
  updateReward,
  thread,
  showVisibility = false,
}: CommentListProps) {
  const tooltipRefs = useRef<any>([]);
  const commentRef = useRef<HTMLDivElement>(null);
  const [edit, setEdit] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<number>(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletedCommentId, setDeletedCommentId] = useState<number>(0);
  const [currentComment, setCurrentComment] = useState("");
  const [currentGroups, setCurrentGroups] = useState<GroupAutocompleteResult[]>(
    [],
  );
  const [currentUsers, setCurrentUsers] = useState<GroupAutocompleteResult[]>(
    [],
  );

  const [displayVisibility, setDisplayVisibility] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>();
  const { user: currentUser } = useCurrentUser();

  const url_mapping: { [id: string]: string } = {
    meetingnote: "meeting_notes",
    opportunity: "opportunity",
  };

  const cancelHandler = (commentId: number, commentText: string) => {
    const divEl = window.document.getElementById(`p_${commentId}`);
    if (divEl) {
      divEl.innerText = commentText;
    }
    setEditingCommentId(0);
    setEdit(false);
    setDisplayVisibility(false);
  };

  const editingHandler = (comment: Comment) => {
    const groups: GroupAutocompleteResult[] = [];
    const users: GroupAutocompleteResult[] = [];
    let isPublicItem: boolean;
    if (comment.access_policy) {
      setIsVisible(true);
      isPublicItem = true;
    } else {
      isPublicItem = false;
      setIsVisible(false);
    }
    (comment.access_policy?.groups || []).map((group) => {
      const gr: GroupAutocompleteResult = {
        label: group.name,
        value: group.id,
      };
      groups.push(gr);
    });
    (comment.access_policy?.users || []).map((user) => {
      const gr: GroupAutocompleteResult = {
        label: user.name,
        value: user.id,
      };
      users.push(gr);
    });
    setCurrentComment(comment.text);
    setEditingCommentId(comment.id);
    setCurrentGroups(groups);
    setCurrentUsers(users);
    setEdit(true);
  };

  const saveHandler = (comment: Comment, currentComment: string) => {
    const groups: Group[] = [];
    const users: Group[] = [];

    (currentGroups || []).map((group) => {
      const gr: Group = {
        name: group.label,
        id: group.value,
      };
      groups.push(gr);
    });
    (currentUsers || []).map((user) => {
      const ur: Group = {
        name: user.label as any,
        id: user.value,
      };
      users.push(ur);
    });

    comment.text = currentComment;
    if (comment.access_policy && comment.access_policy.groups) {
      comment.access_policy.groups = isVisible === true ? groups : [];
    }

    if (comment.access_policy && comment.access_policy.users) {
      comment.access_policy.users = isVisible === true ? (users as any) : [];
    }
    let access_policy_1 = {
      groups: groups,
      users: users as any,
    };
    if (!comment.access_policy) {
      comment.access_policy = access_policy_1;
    }
    editComment(comment);
    setEditingCommentId(0);
    setEdit(false);
  };

  useEffect(() => {
    if (commentRef && commentRef.current) {
      const handleScroll = () => {
        if (
          commentRef.current &&
          commentRef.current.offsetHeight + commentRef.current.scrollTop >=
            commentRef.current.scrollHeight - 20 &&
          canLoadMore
        ) {
          loadMore();
        }
      };
      commentRef.current.addEventListener("scroll", handleScroll);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        commentRef.current?.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef, comments]);

  return (
    <div className="-mr-5 flex flex-col overflow-x-hidden! overflow-y-auto">
      <div
        className="show-scrollbar mt-6 h-fit! divide-y divide-gray-300 pr-5 text-xs"
        ref={commentRef}
      >
        {(comments || []).map((comment, index) => {
          const isCurrentEditingComment = editingCommentId === comment.id;
          const viewStatus =
            comment.access_policy?.groups &&
            comment.access_policy?.groups.length > 0 &&
            comment.access_policy?.users &&
            comment.access_policy?.users.length > 1
              ? comment.access_policy?.groups
                  .map((group) => group.name)
                  .join(", ") + " & Selected Users"
              : comment.access_policy?.groups &&
                  comment.access_policy?.groups.length > 0
                ? comment.access_policy?.groups
                    .map((group) => group.name)
                    .join(", ")
                : comment.access_policy?.users &&
                    comment.access_policy?.users.length > 0
                  ? "Selected Users"
                  : "all";
          return (
            <div
              key={comment.id}
              className={`flex w-full flex-row ${index !== 0 ? "pt-5" : ""}`}
            >
              <div className="ml-2 flex flex-1 flex-col">
                <div className={`flex flex-col flex-wrap justify-between`}>
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                      <div>
                        <Avatar
                          className="bg-gray-300"
                          name={comment.user.name}
                          size={26}
                          url={comment.user.image_url}
                        />
                      </div>
                      <div className="ml-2 flex flex-col">
                        <span className="font-bold">{comment.user.name} </span>
                        <ReactTimeAgo
                          date={new Date(comment.created_at)}
                          locale="en-US"
                        />
                      </div>
                    </div>
                    <div className="items-center">
                      <span className="font-xs mr-2 flex flex-row items-center text-gray-500">
                        <div className="group relative">
                          {!thread && (
                            <EyeIcon className="group mr-1 h-4 w-4 cursor-pointer stroke-gray-500 hover:fill-blue-500 hover:stroke-white" />
                          )}
                          <div
                            ref={(ref: any) => {
                              tooltipRefs.current[comment.id] = ref;
                            }}
                            className="z-top invisible absolute right-0 w-max max-w-[200px] rounded-md border bg-black p-2 text-xs text-white shadow-md group-hover:visible"
                          >
                            <div className="text-2xs flex w-full flex-wrap">
                              {viewStatus.toLowerCase()[0].toUpperCase() +
                                viewStatus.toLowerCase().substring(1)}
                            </div>
                            {comment.access_policy?.users &&
                              comment.access_policy?.users.length > 0 &&
                              comment.access_policy?.users.map(
                                (user) =>
                                  user.id !== comment.user.id && (
                                    <Link
                                      key={user.id}
                                      href={`/people/${user.person}`}
                                      passHref
                                      legacyBehavior
                                    >
                                      <div className="owner mt-2 flex cursor-pointer gap-x-2">
                                        <Avatar
                                          key={user.id}
                                          className="bg-gray-300"
                                          name={user.name}
                                          size={24}
                                          url={user.image_url}
                                        />
                                        <span className="text-2xs justify-center rounded-md border bg-black px-2 py-1 text-white">
                                          {user.name}
                                        </span>
                                      </div>
                                    </Link>
                                  ),
                              )}
                          </div>
                        </div>

                        {thread &&
                          updateVote &&
                          updateReward &&
                          comment.votes && (
                            <>
                              <button
                                className={`ml-5 flex h-6 w-10 items-center justify-center gap-x-1 rounded-xl bg-gray-200 ${
                                  comment.votes.filter(
                                    (vote) => vote.owner.id === currentUser.id,
                                  ).length > 0
                                    ? "bg-blue-900! text-white"
                                    : ""
                                }`}
                                onClick={() => updateVote(comment.id)}
                              >
                                <HandThumbUpIcon className="h-3 w-3" />

                                {comment.votes.length}
                              </button>
                              {thread.owner.id === currentUser.id && (
                                <GiftIcon
                                  className={`ml-2 h-4 w-4 cursor-pointer ${
                                    comment.reward
                                      ? "fill-yellow-500 stroke-white"
                                      : "animate-pulse fill-gray-400 stroke-white"
                                  }`}
                                  onClick={() => updateReward(comment.id)}
                                />
                              )}
                            </>
                          )}

                        {currentUser.id === comment.user.id && (
                          <div className="flex flex-row items-center">
                            {!isCurrentEditingComment && (
                              <PencilIcon
                                key={`edit_${comment.id}`}
                                className="mr-1 h-4 w-4 cursor-pointer stroke-blue-900"
                                onClick={() => editingHandler(comment)}
                              />
                            )}
                            <TrashIcon
                              className="h-4 w-4 cursor-pointer text-red-500"
                              onClick={() => {
                                setConfirmDelete(true);
                                setDeletedCommentId(comment.id);
                              }}
                            />
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  {comment?.source &&
                    comment?.source?.model !== "organization" && (
                      <span className="my-1 text-xs font-medium whitespace-pre-line">
                        <Link
                          className="text-blue-900"
                          href={`/${url_mapping[comment.source.model]}/${
                            comment.source.id
                          }`}
                        >
                          {comment.source.name}
                        </Link>
                      </span>
                    )}
                  <div
                    className={`my-2 ${
                      edit &&
                      editingCommentId === comment.id &&
                      "rounded-xl border border-gray-300 p-3"
                    }`}
                  >
                    <div className="break-word-custom flex flex-col gap-y-2">
                      {!isCurrentEditingComment && (
                        <CommentInput disabled={true} value={comment.text} />
                      )}
                      {edit && isCurrentEditingComment && (
                        <CommentInput
                          isEdit={edit && isCurrentEditingComment}
                          value={currentComment}
                          onChange={(value) => setCurrentComment(value)}
                        />
                      )}
                      {edit && isCurrentEditingComment && (
                        <div className="my-2 flex w-full flex-row justify-end gap-x-2">
                          {showVisibility && (
                            <div
                              className={`flex flex-row items-center justify-center gap-x-1 text-xs ${
                                displayVisibility
                                  ? "border-[1px] border-blue-900 bg-blue-900"
                                  : "border-[1px] border-blue-900"
                              } w-32 cursor-pointer rounded-full py-1`}
                              onClick={() =>
                                setDisplayVisibility(!displayVisibility)
                              }
                            >
                              <EyeIcon
                                className={`h-4 w-4 ${
                                  displayVisibility
                                    ? "fill-blue-900 text-white"
                                    : "fill-white text-blue-900"
                                } `}
                              />
                              <div
                                className={`text-2xs ${
                                  displayVisibility
                                    ? "text-white"
                                    : "text-blue-900"
                                }`}
                              >
                                {displayVisibility
                                  ? "Hide Visibility"
                                  : "Show Visibility"}
                              </div>
                            </div>
                          )}
                          <button
                            className="btn-secondary"
                            onClick={() =>
                              cancelHandler(comment.id, comment.text)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-primary"
                            onClick={() => saveHandler(comment, currentComment)}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col justify-center">
                      {edit &&
                        editingCommentId === comment.id &&
                        showVisibility &&
                        displayVisibility && (
                          <div className="my-3">
                            <div className="flex flex-col items-center">
                              <div className="mb-1 flex w-full flex-row items-center font-medium">
                                Visibility
                                <Tooltip
                                  title="What is Visibility?"
                                  content="Visibility allows you to have an option of deciding who can view the activity. All user activity by default is only visible to user groups or funds that you belong to."
                                />
                              </div>
                              <div className="flex w-full flex-row items-center">
                                <input
                                  checked={!isVisible}
                                  type="radio"
                                  id="public"
                                  value="public"
                                  onChange={() => {
                                    setIsVisible(false);
                                  }}
                                />
                                <label
                                  className="font-xs ml-3 block text-gray-700"
                                  htmlFor="public"
                                >
                                  Public
                                </label>
                                <input
                                  checked={isVisible}
                                  type="radio"
                                  id="only"
                                  value="Specific groups/users"
                                  className="ml-6"
                                  onChange={() => {
                                    setIsVisible(true);
                                  }}
                                />
                                <label
                                  className="font-xs ml-3 block text-gray-700"
                                  htmlFor="only"
                                >
                                  Specific groups/users
                                </label>
                              </div>
                            </div>
                            {isVisible === true && (
                              <>
                                <label
                                  className="mt-2.5 mb-2 flex"
                                  htmlFor="groupsField"
                                >
                                  <div className="font-medium">
                                    Specific groups/users
                                  </div>
                                  <div className="h-4 w-4 text-red-600">*</div>
                                </label>
                                <div className="flex flex-col space-y-2 text-xs">
                                  <div className="w-full text-gray-700">
                                    <AutocompleteMultiselect
                                      autocompleteEndpoint={
                                        "/api/users/autocomplete/allgroups"
                                      }
                                      selected={currentGroups}
                                      onChange={(e: any) => setCurrentGroups(e)}
                                    />
                                  </div>
                                  <div className="w-full text-gray-700">
                                    <AutocompleteMultiselect
                                      autocompleteEndpoint={`/api/users/autocomplete/users`}
                                      selected={currentUsers as any}
                                      onChange={(e: any) => setCurrentUsers(e)}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <ConfirmationModal
          open={confirmDelete && !!deletedCommentId}
          title={`Are you sure to delete this comment?`}
          subtitle="This action cannot be undone"
          processing={isLoading}
          processingText={"Deleting..."}
          submitButtonText={"Delete"}
          onClose={() => {
            setConfirmDelete(false);
            setDeletedCommentId(0);
          }}
          onSubmit={() => {
            deleteComment(deletedCommentId);
            setConfirmDelete(false);
            setDeletedCommentId(0);
          }}
        />
      </div>
      {isLoading && (
        <div className="flex w-full items-center justify-center">
          <SpinnerCustom className="h-12! w-28!" />
        </div>
      )}
    </div>
  );
}
