import { createContext, Dispatch } from "react";

export interface CommentContextData {
  commentText: any;
  setCommentText: Dispatch<any>;
}

export const CommentContext = createContext<CommentContextData>({
  commentText: null,
  setCommentText: (() => undefined) as Dispatch<any>,
});
